import React from 'react'
import "react-multi-carousel/lib/styles.css";
import line from '../../assetsRoot/heading/Line 415.png'
import galleryimg from '../../assetsRoot/heading/GALLERY.png'
import Marquee from "react-fast-marquee";
import Gallery1 from '../../assetsRoot/Gallery/gallery (1).jpeg'
import Gallery2 from '../../assetsRoot/Gallery/gallery (2).jpeg'
import Gallery3 from '../../assetsRoot/Gallery/gallery (3).jpeg'
import Gallery4 from '../../assetsRoot/Gallery/gallery (4).jpeg'
import Gallery5 from '../../assetsRoot/Gallery/gallery (5).jpeg'
import Gallery6 from '../../assetsRoot/Gallery/gallery (6).jpeg'
import Gallery7 from '../../assetsRoot/Gallery/gallery (7).jpeg'
import Gallery8 from '../../assetsRoot/Gallery/gallery (8).jpeg'
import Gallery9 from '../../assetsRoot/Gallery/gallery (9).jpeg'
import Gallery10 from '../../assetsRoot/Gallery/gallery (10).jpeg'
import Gallery11 from '../../assetsRoot/Gallery/gallery (11).jpeg'
import Gallery12 from '../../assetsRoot/Gallery/gallery (12).jpeg'
import Gallery13 from '../../assetsRoot/Gallery/gallery (14).jpeg'

function Gallery() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div id="photo" className=' py-12  text-4xl bg-background1'>
                <div className='lg:px-[8%] md:px-[8%] px-[5%] flex items-center justify-between  lg:pb-5 gap-8 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={galleryimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>gallery</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <Marquee
                    className="w-100 testing"
                    direction="left"
                    speed={50} // Adjust the speed to match scrollamount
                    loop={0} // Infinite looping
                    gradient={false} // Disable gradient
                >
                    <div className='flex justify-between items-center gap-10 ms-10'>
                        <div >
                            <img src={Gallery1} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg ' />
                        </div>
                        <div >
                            <img src={Gallery2} alt="Gallery" className='rounded-xl h-64  border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery3} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery4} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery5} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery6} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery7} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery8} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery9} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery10} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery11} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery12} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img src={Gallery13} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                    </div>
                </Marquee>
            </div>
        </>
    )
}

export default Gallery
