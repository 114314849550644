import React from 'react'
// import team from '../../assetsRoot/home/Rectangle 34625543.png'
// import team from '../../assetsRoot/Team/team.jpg'
import line from '../../assetsRoot/heading/Line 415.png'
import teamimg from '../../assetsRoot/heading/MANAGEMENT TEAM.png'
import person1 from '../../assetsRoot/Team/Ellipse 8800.png'
import person2 from '../../assetsRoot/Team/Ellipse 8801.png'
import bgi1 from '../../assetsRoot/Team/Rectangle 34625545.png'
import bgi2 from '../../assetsRoot/Team/Rectangle 34625547.png'
import bgi3 from '../../assetsRoot/Team/Rectangle 34625546.png'

function Team() {
    return (
        <>
            <div className='xl:px-[8%] px-[5%] py-12 bg-background3'>
                <div className='flex items-center justify-between  lg:pb-5 gap-12 mb-8' >
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={teamimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>management team</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='grid grid-cols-12 lg:gap-24 md:gap-10 md:gap-y-36 gap-y-40 mt-44 relative'>
                    <div className='absolute top-5 left-10 hidden xl:block lg:block md:block'>
                        <img className='z-10 ' src={bgi1} alt="background 1" />
                    </div>
                    <div className='absolute right-10 -bottom-10 hidden xl:block lg:block md:block'>
                        <img className='z-10 ' src={bgi2} alt="background 2" />
                    </div>
                    <div className='col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12  p-8  lg:ms-28
                     bg-background2 relative z-50'>
                        <div className='absolute bottom-20 -right-10 hidden xl:block lg:block md:block'>
                            <img className='' src={bgi3} alt="background 3" />
                        </div>
                        <div className='relative z-40 flex justify-around'>
                            <div className='absolute  p-3   -top-36'>
                                <img src={person2} alt="person 1" />
                            </div>
                            <div>
                                <p className='mt-28 text-para lg:text-sm xl:text-base mb-16'>
                                    Graduate in commerce is very good in working out in financial matter along with procurement of vehicle
                                    through financial institution, Has a good approach in helping out to own company based vehicle through
                                    own sources, Has the capacity to sustain any financial load at any point of circumstances.
                                </p>
                                <p className='text-primary   float-end'>-Muralidhar. C. K</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 p-8  lg:me-28
                     bg-background2 relative'>
                        <div className='absolute -top-28 left-36 '>
                            <img className='' src={bgi3} alt="background 3" />
                        </div>
                        <div className='relative z-40 flex justify-around'>
                            <div className='  p-3 absolute  -top-36 '>
                                <img src={person1} alt="person 7" />
                            </div>
                            <div>
                                <p className='mt-28 text-para lg:text-sm xl:text-base mb-6'>
                                    Graduate in science has worked in marketing institutions and construction company, Has affective
                                    work man ship in operations with vast experience of 10 years. Has worked with Major Clients like
                                    Jones Lang Laselle, Hewlett Packard, CB Richard Ellis an associate with HSBC, latest worked as a
                                    manager in transport with HSBC (CBRE), Has the ability to expose all managerial skills to ensure
                                    smooth operations throughout the process.
                                </p>
                                <p className='text-primary   float-end'>-Nagesh .M .K</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 flex justify-around p-8  lg:ms-28
                     bg-background2 relative' style={{ backgroundImage: `url(${bgi1})`, backgroundRepeat: 'no-repeat' }}> */}
        </>
    )
}

export default Team
