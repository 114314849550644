import React, { useEffect, useState } from 'react'
import photo from '../../assetsRoot/Logo/Frame 1597882815.png'
// import { MdOutlineMail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
// import { FaFacebook } from "react-icons/fa";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { FaYoutube } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
import { TfiAlignJustify } from "react-icons/tfi";
import { FaXmark } from "react-icons/fa6";
import Modal from './BookingModal';
import FixedNavBar from './FixedNavBar'
import Aos from 'aos'
import 'aos/dist/aos.css';
import { FaArrowRight } from 'react-icons/fa6'


const NavBar = () => {

    const [isOpen, setisopen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    function scrollToSection(id, height) {
        const section = document.getElementById(id);
        if (section) {
            const offset = section.getBoundingClientRect().top + window.pageYOffset - height;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    }
    const [fixedNav, setFixedNav] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight * 0.7;
            // If scroll position reaches 100vh (windowHeight)
            if (scrollPosition >= windowHeight) {
                setFixedNav(true);
            } else {
                setFixedNav(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div>

                {/* <div className='hidden bg-[#f8f4ff] px-[5%] py-[3%]  justify-between md:px-[10%] md:py-2 md:flex md:justify-between lg:px-[10%] lg:py-2 lg:flex lg:justify-between'>

                    <div className='block md:flex md:gap-6 lg:flex  lg:gap-6  '>
                        <h1 className=' flex items-center text-[18px] md:flex md:items-center md:text-[18px] lg:flex lg:items-center lg:text-[16px] cursor-pointer'><span className='text-primary'><MdOutlineMail className='text-[20px] ' /></span><span className='pl-2 md:pl-2 lg:pl-2  text-primary font-semibold font-sans'>info@annapoorna.in </span></h1>
                        <h1 className='flex items-center text-[18px] md:flex md:items-center md:text-[18px]  lg:flex lg:items-center lg:text-[16px] cursor-pointer'><span className='text-primary'><FaPhoneAlt /></span><span className='pl-2 md:pl-2 lg:pl-2 text-[16px] text-primary font-semibold  font-sans'>+91-80-4115 5444</span></h1>
                    </div>
                    <div className=' animate-pulse  hidden  text-end gap-2 md:flex md:text-end md:gap-2 lg:flex lg:gap-2 lg:text-end '>
                        <a href="https://www.facebook.com/ " target="blank" className='cursor-pointer' ><FaFacebook className=' bg-primary text-[24px] px-1 md:text-[24px] md:px-1 lg:text-[24px] text-white  lg:px-1' /></a>
                        <a href="https://api.whatsapp.com/send?phone=918041155444" target="blank" className='cursor-pointer' ><IoLogoWhatsapp className='bg-primary text-[24px] px-1 md:text-[24px] md:px-1 lg:text-[24px] text-white lg:px-1' /></a>
                        <a href="https://www.instagram.com/ " target="blank" className='cursor-pointer' ><FaInstagram className='bg-primary text-[24px] px-1 md:text-[24px] md:px-1 lg:text-[24px] text-white  lg:px-1' /></a>
                        <a href="https://www.youtube.com/ " target="blank" className='cursor-pointer' ><FaYoutube className='bg-primary text-[24px] px-1 md:text-[24px] md:px-1 lg:text-[24px] text-white lg:px-1' /></a>
                    </div>
                </div> */}
                <div >
                    <div>
                        {fixedNav ? <FixedNavBar /> : (
                            <div  >
                                <header className='bg-white px-[5%] py-3 xl:px-[8%] lg:py-2 '>
                                    <nav className="justify-between items-left md:flex md:justify-between md:items-center lg:flex xl:flex xl:justify-between xl:items-center lg:justify-between lg:items-center">
                                        <div className=" flex md:flex lg:flex xl:flex">
                                            <img className='w-36 md:w-16 lg:w-52 ' src={photo} alt="anp icon" />
                                        </div>

                                        <div>

                                            <ul className="hidden  md:flex md:items-center  md:gap-3 md:text-[14px] xl:flex xl:items-center xl:gap-8 lg:flex lg:items-center  lg:gap-5 xl:text-[16px] lg:text-[14px]">
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('home', 90)}>Home</li>
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('about', 90)}>About Us</li>
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('serv', 90)} >Services</li>
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('fleet', 90)}>Our Fleet</li>
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('photo', 90)}>Gallery</li>
                                                <li className=" cursor-pointer" onClick={() => scrollToSection('contact', 90)}>Contact Us</li>
                                                <li><button onClick={() => openModal(true)} className=" flex p-3 px-2 border-none bg-primary text-white rounded-[10px]">Book Now<span className='ms-2 pt-1'><FaArrowRight /></span></button></li>
                                            </ul>


                                        </div>
                                        {/* <div className='hidden  md:block lg:block'>
                                            <button onClick={() => openModal(true)} className=" rounded-[10px] px-4 py-2  bg-primary text-[14px] font-sans text-white md:ml-1 hover:bg-slate-200 hover:text-black hover:font-semibold cursor-pointer">Book Now</button>
                                        </div> */}

                                        {/* toggle */}
                                        <div onClick={() => setisopen(!isOpen)} className='w-7 h-8  absolute right-8 top-6 cursor-pointer md:hidden lg:hidden'>
                                            {
                                                isOpen ? <FaXmark className='' /> : <TfiAlignJustify className='' />
                                            }
                                        </div>
                                        <div className={`xl:hidden lg:hidden md:hidden sm:flex  text-[20px] w-full items-center absolute pb-12 space-y-6 pl-9  mt-3 py-4 z-50   left-0  transition-all bg-white  duration-500 ease-in ${isOpen ? 'top-13' : 'top-[-490px]'}`}>
                                            <ul >
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('home', 50)
                                                    setisopen(false)
                                                }}>Home</li>
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('about', 70)
                                                    setisopen(false)
                                                }}>About Us</li>
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('serv', 70)
                                                    setisopen(false)
                                                }} >Services</li>
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('fleet', 70)
                                                    setisopen(false)
                                                }}>Our Fleet</li>
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('photo', 70)
                                                    setisopen(false)
                                                }}>Gallery</li>
                                                <li className="font-semibold font-sans cursor-pointer py-2" onClick={() => {
                                                    scrollToSection('contact', 70)
                                                    setisopen(false)
                                                }}>Contact Us</li>



                                            </ul>

                                            <button onClick={() => openModal(true)} className=" block lg:hidden md:hidden xl:hidden rounded-[10px] px-4 py-2  bg-primary text-[14px] font-sans text-white hover:bg-slate-200 hover:text-black hover:font-semibold cursor-pointer">Book Now</button>

                                        </div>

                                    </nav>
                                </header>
                            </div>
                        )}
                    </div>

                </div>
                <Modal isOpen={isModalOpen} onClose={closeModal} />
            </div>

        </>
    )
}

export default NavBar