import React, { useEffect } from 'react'
import line from '../../assetsRoot/heading/Line 415.png'
import whychooseimg from '../../assetsRoot/heading/Why Choose Us.png'
import car from '../../assetsRoot/home/Group 1597883155.png'
import shade from '../../assetsRoot/home/Ellipse 8796.png'
import truck from '../../assetsRoot/home/icon/Capa_1.png'
import location from '../../assetsRoot/home/icon/Capa_1 (1).png'
import coin from '../../assetsRoot/home/icon/Vector.png'
import price from '../../assetsRoot/home/icon/Capa_1 (2).png'
import Aos from 'aos';

function WhyChooseUs() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <>
            <div className=' py-10 bg-background '>
                <div className=' xl:px-[8%] px-[5%] flex items-center justify-between  lg:pb-5 gap-12 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={whychooseimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>why chooose us</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='xl:px-[8%] px-[5%] mb-5 flex justify-center text-center'>
                    <p className='text-para text-sm xl:text-base'>ANP travels focuses on providing professional travel solutions in Bangalore. After years of understanding the travel business and dealing with various client issues, we have one of the largest car and bus networks and services with a personal touch.</p>
                </div>

                <div className='flex '>
                    <div className='lg:flex  items-end '>
                        <div className='relative hidden lg:block'>
                            <img data-aos="fade-right" className='absolute z-50 -bottom-56 xl:-bottom-64 2xl:-bottom-72 -left-14 object-cover w-full h-[600px] xl:h-[700px] 2xl:h-[800px] transition-all duration-300 ease-in-out hover:scale-105    ' src={car} alt="background car" />
                            <img data-aos="fade-right" className='-z-10 w-[1500px] transition-all duration-200 ease-in-out hover:scale-105 ' src={shade} alt="car shade" />
                        </div>
                    </div>

                    <div className=' xl:pe-[8%]  px-[5%] mt-8  lg:mt-10'>
                        <div className='grid grid-cols-12 lg:gap-16 md:gap-5 gap-y-16'>
                            <div className='col-span-12 md:col-span-6 transition-all duration-300 ease-in-out group hover:border-2 hover:border-primary hover:shadow-xl bg-white shadow-md border-2 rounded-xl py-5 px-7 relative mb-1 lg:mb-0 '>
                                <div className='bg-primary flex justify-center p-3 absolute items-center place-content-start -top-7  rounded-lg w-[55px] h-[55px]'>
                                    <img className='group-hover:scale-125 transition-all duration-300 ease-in-out' src={truck} alt="truck icon" />
                                </div>
                                <h5 className='text-primary lg:text-base xl:text-xl mb-2 mt-4 xl:mt-8'>Fast & Easy Booking</h5>
                                <p className='text-para text-sm lg:text-xs xl:text-sm 2xl:text-base'>Book your car online or offline. Follow the easy process to book your car
                                    online. Or just call us any time from anywhere</p>
                            </div>
                            <div className='col-span-12 md:col-span-6 transition-all duration-300 ease-in-out group hover:border-2 hover:border-primary hover:shadow-xl bg-white shadow-md border-2 rounded-xl py-5 px-7 relative mb-1 lg:mb-0 '>

                                <div className='bg-primary flex justify-center p-3 absolute items-center place-content-start -top-7 rounded-lg  w-[55px] h-[55px]'>
                                    <img className='group-hover:scale-125 transition-all duration-300 ease-in-out' src={location} alt="truck icon" />
                                </div>
                                <h5 className='text-primary lg:text-base xl:text-xl mb-2 mt-4 xl:mt-8'>Many Pickup Location</h5>
                                <p className='text-para text-sm lg:text-xs xl:text-sm 2xl:text-base'>We have an extensive number of cars that will be available to pick
                                    you up from any location through out the country</p>
                            </div>
                            <div className='col-span-12 md:col-span-6 transition-all duration-300 ease-in-out group hover:border-2 hover:border-primary hover:shadow-xl bg-white shadow-md border-2 rounded-xl py-5 px-7 relative mb-1 lg:mb-0 '>
                                <div className='bg-primary flex justify-center p-3 absolute items-center place-content-start -top-7 rounded-lg w-[55px] h-[55px]'>
                                    <img className='group-hover:scale-125 transition-all duration-300 ease-in-out' src={coin} alt="truck icon" />
                                </div>
                                <h5 className='text-primary lg:text-base xl:text-xl mb-2 mt-4 xl:mt-8'>Satisfied Customers</h5>
                                <p className='text-para text-sm lg:text-xs xl:text-sm 2xl:text-base'>We have 70,000+ happy customers and it’s increasing. View  our review section
                                    to get their feedback about our service</p>
                            </div>
                            <div className='col-span-12 md:col-span-6 transition-all duration-300 ease-in-out group hover:border-2 hover:border-primary hover:shadow-xl bg-white shadow-md border-2 rounded-xl py-5 px-7 relative mb-1 lg:mb-0 '>
                                <div className='bg-primary flex justify-center p-3 absolute items-center place-content-start -top-7 rounded-lg  w-[55px] h-[55px]'>
                                    <img className='group-hover:scale-125 transition-all duration-300 ease-in-out' src={price} alt="truck icon" />
                                </div>
                                <h5 className='text-primary lg:text-base xl:text-xl mb-2 mt-4 xl:mt-8'>Best Price</h5>
                                <p className='text-para text-sm lg:text-xs xl:text-sm 2xl:text-base'>We offer fair and competitive pricing on all our services. Plus, we provide free estimates
                                    and various service packages to suit your budget.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyChooseUs
