import React from 'react'
import Marquee from "react-fast-marquee";
import client1 from '../../assetsRoot/Client/client1.jpg'
import client3 from '../../assetsRoot/Client/client3.jpg'
import client4 from '../../assetsRoot/Client/client4.jpg'
import client5 from '../../assetsRoot/Client/client5.jpg'
import client6 from '../../assetsRoot/Client/client6.jpg'
import client7 from '../../assetsRoot/Client/client7.jpg'
import client8 from '../../assetsRoot/Client/client8.jpg'
import client9 from '../../assetsRoot/Client/client9.jpg'


function OurClients() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (
        <>
            <div className=' bg-gradient-to-r from-green to-primary py-8'>
                <Marquee
                    className="w-100 testing"
                    direction="left"
                    speed={50} // Adjust the speed to match scrollamount
                    loop={0} // Infinite looping
                    gradient={false} // Disable gradient
                >
                    <div className='flex justify-between items-center gap-10 ms-10'>
                        <div >
                            <img src={client1} alt="client 1" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client3} alt="client 3" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client4} alt="client 4" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client5} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client6} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client7} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client8} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client9} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client5} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client6} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client8} alt="client 5" className='rounded-md h-20' />
                        </div>
                    </div>
                </Marquee>
            </div>
        </>
    )
}

export default OurClients
