import React from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import homeCar from '../../assetsRoot/home/image-removebg-preview (1) 2.png'
// import BookingForm from '../../components/NavBar/Form/BookingForm'
import BookIndex from '../../componentsRoot/instant booking/BookIndex'
import OurClients from './OurClients'

function Banner() {
    return (
        <>
            <div id="home" className=' py-5 lg:py-10 bg-banner bg-cover'>
                <div className='grid grid-cols-12 gap-5 py-5 xl:px-[8%] px-[5%] lg:mb-5'>
                    <div className='col-span-12 lg:col-span-6 mt-10 relative order-1' >
                        <div className='order-1'>
                            <h2 className='xl:text-4xl lg:text-3xl text-xl font-semibold lg:pt-5 py-3 text-white '>Explore The Road Ahead With  <span className='gradient-text'>Comfort And Style</span></h2>
                            <p className='lg:w-[66%] lg:pt-5 text-white lg:leading-[30px] lg:text-sm xl:text-lg   xl:leading-[40px]'>Creating quality Lifestyles, building stronger communities Quality Construction Services for Residential, Commercial, and Industrial
                                Projects</p>
                            <button className='flex lg:mt-10 mt-3 border-2 text-white bg-primary border-primary border-solid rounded-lg p-2'>
                                Contact Us<span className='ms-2 pt-1'><FaArrowRight /></span>
                            </button>
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-6 float-end relative order-2'>
                        <div style={{ scrollbarWidth: 'none' }} className=' order-2  lg:order-none md:order-none  overflow-hidden col-span-12 md:col-span-12 lg:col-span-5 overflow-y-scroll scroll-hidden h-[500px] md:h-[450px] xl:h-[450px] lg:h-[360px] lg:mt-10 mt-5'>
                            <div>
                                <BookIndex />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner
