import React, { useEffect, useState } from 'react'
import photo from '../../assetsRoot/Logo/Frame 1597882815.png'
import { TfiAlignJustify } from "react-icons/tfi";
import { FaXmark } from "react-icons/fa6";
import Modal from './BookingModal';
import Aos from 'aos'
import 'aos/dist/aos.css';
import { FaArrowRight } from 'react-icons/fa6'


const FixedNavBar = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    const [isOpen, setisopen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    function scrollToSection(id, height) {
        const section = document.getElementById(id);
        if (section) {
            const offset = section.getBoundingClientRect().top + window.pageYOffset - height;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    }
    return (
        <div>
            <div>
                <div style={{ position: 'fixed', left: '0', right: '0', top: '0', zIndex: '100' }}>

                    <header data-aos='fade-down' className='bg-white w-full shadow-md px-[5%] py-3 md:py-3 xl:px-[8%] lg:py-3 '>
                        <nav className="flex justify-between items-left md:flex md:justify-between md:items-center lg:flex xl:flex xl:justify-between xl:items-center lg:justify-between lg:items-center">

                            <div className=" flex md:flex lg:flex xl:flex">
                                <img className='w-36  md:w-16  lg:w-52  ' src={photo} alt="anp icon" />
                            </div>

                            <div className='flex float-end' >


                                <ul className="hidden  md:flex md:items-center  md:gap-2 md:text-[12px] xl:flex xl:items-center xl:gap-8 lg:flex lg:items-center  lg:gap-4  xl:text-[16px] lg:text-[14px]">

                                    <li className=" cursor-pointer" onClick={() => scrollToSection('home', 90)}>Home</li>
                                    <li className="cursor-pointer" onClick={() => scrollToSection('about', 90)}>About Us</li>
                                    <li className=" cursor-pointer" onClick={() => scrollToSection('serv', 90)} >Services</li>
                                    <li className=" cursor-pointer" onClick={() => scrollToSection('fleet', 90)}>Our Fleet</li>
                                    <li className=" cursor-pointer" onClick={() => scrollToSection('photo', 90)}>Gallery</li>
                                    <li className=" cursor-pointer" onClick={() => scrollToSection('contact', 90)}>Contact Us</li>
                                    <li><button onClick={() => openModal(true)} className=" flex p-3 px-2 border-none bg-primary text-white rounded-[10px]">Book Now<span className='ms-2 pt-1'><FaArrowRight /></span></button></li>
                                </ul>


                            </div>
                            {/* <div className='hidden  md:block lg:block'>
                                <button onClick={() => openModal(true)} className=" rounded-[10px] px-4 py-2  bg-primary text-[14px] font-sans text-white md:ml-1 lg:ml-5">Book Now</button>
                            </div> */}
                            <div onClick={() => setisopen(!isOpen)} className='w-7 h-8  absolute right-8 top-6 cursor-pointer md:hidden lg:hidden  '>
                                {
                                    isOpen ? <FaXmark className='' /> : <TfiAlignJustify className='' />
                                }
                            </div>

                            <ul className={`lg:hidden md:hidden sm:flex  text-[20px] w-[100%] items-center absolute pb-12 space-y-4 pl-9  mt-0 py-4  pt-10 z-[-1] left-0  transition-all bg-white  duration-500 ease-in ${isOpen ? 'top-13' : 'top-[-490px]'}`} >
                                <li className="cursor-pointer" onClick={() => {
                                    scrollToSection('home', 50)
                                    setisopen(false)
                                }}>Home</li>
                                <li className=" cursor-pointer" onClick={() => {
                                    scrollToSection('about', 50)
                                    setisopen(false)
                                }}>About Us</li>
                                <li className=" cursor-pointer" onClick={() => {
                                    scrollToSection('serv', 70)
                                    setisopen(false)
                                }} >Services</li>
                                <li className=" cursor-pointer" onClick={() => {
                                    scrollToSection('fleet', 70)
                                    setisopen(false)
                                }}>Our Fleet</li>
                                <li className="cursor-pointer" onClick={() => {
                                    scrollToSection('photo', 70)
                                    setisopen(false)
                                }}>Gallery</li>
                                <li className=" cursor-pointer" onClick={() => {
                                    scrollToSection('contact', 70)
                                    setisopen(false)
                                }}>Contact Us</li>

                                <button onClick={() => openModal(true)} className="rounded-[10px] px-4 py-2  bg-primary text-[14px] text-white">Book Now</button>

                            </ul>

                        </nav>
                    </header>
                </div>

            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    )
}

export default FixedNavBar