import React from 'react'
// import NewsLetter from './NewsLetter'
import ContactUs from '../containers/Home/ContactUs'
import fb from '../assetsRoot/social-icon/fb.png'
import insta from '../assetsRoot/social-icon/insta.png'
import twit from '../assetsRoot/social-icon/twit.png'
import logo from '../assetsRoot/Logo/Frame 1597882815.png'
import phoneicon from '../assetsRoot/home/icon/Group 1597882866.png'
import mailicon from '../assetsRoot/home/icon/Group 1597882865.png'

function FooterIndex() {
    return (
        <>

            <ContactUs />
            {/* <NewsLetter /> */}
            <div className='xl:px-[8%] px-[5%] py-12 bg-background'>
                <div className='flex flex-col lg:flex-row gap-8 justify-between capitalize'>
                    <div className='col-span-12 md:col-span-6 lg:col-span-3 '>
                        <h3 className='uppercase lg:text-2xl mb-5'>services</h3>
                        <div className='leading-7 text-para lg:text-sm xl:text-base'>
                            <p>Corporate Travels</p>
                            <p>Cab service </p>
                            <p>taxi service</p>
                            <p>bus service</p>
                            <p>tempo travels</p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-6 lg:col-span-3'>
                        <h3 className='uppercase lg:text-2xl mb-5'>Gallery</h3>
                        <div className='leading-7 text-para lg:text-sm xl:text-base'>
                            <p>Bus vehicles</p>
                            <p>car vehicles</p>
                            <p>other vehicles</p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-6 lg:col-span-3'>
                        <h3 className='uppercase lg:text-2xl mb-5'>links</h3>
                        <div className='leading-7 text-para lg:text-sm xl:text-base'>
                            <p>performance</p>
                            <p>terms and conditions</p>
                            <p>value additions</p>
                            <p>our clients</p>
                            <p>why  us</p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-6 lg:col-span-3 text-para lg:text-sm xl:text-base'>
                        <img className='w-56 bg-white pb-5' src={logo} alt="" />
                        <p className='pb-2'>call us:</p>
                        <div className='flex pb-2 items-center'>
                            <img className='w-8 h-8 me-2' src={phoneicon} alt="" />
                            <p className='text-para text-center'> +91-98809 99994 </p>
                        </div>
                        {/* <div className='flex pb- items-center2 '>
                            <img className='w-8 h-8 me-2' src={phoneicon} alt="" />
                            <p className='text-para text-center'>+91-98808 44220</p>
                        </div> */}
                        <div className='flex pb-2  items-center'>
                            <img className='w-8 h-8 me-2' src={mailicon} alt="" />
                            <p className='text-para text-center'>info@annapoorna.in</p>
                        </div>
                        {/* <p className='pt-5 pb-2 text-md'>Follow us on:</p>
                        <p className='flex gap-4'>
                            <img className='w-[35px]' src={fb} alt="fb" />
                            <img className='w-[35px]' src={insta} alt="insta" />
                            <img className='w-[35px]' src={twit} alt="twit" />
                        </p> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default FooterIndex
